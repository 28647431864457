import React, { useContext } from "react";
import { Menu } from "antd";
import { NavLink } from "react-navi";

import { MeContext } from "../../features/me";
import {
  ROLE_ADMIN,
  ROLE_COMMISSION,
  ROLE_DIRECTOR
} from "../../lib/constants";
import withRouter from "../../utils/with-router";

const tabKey = {
  Partage: "/partage",
  Historique: "/historique",
  "En cours": "/validation",
  "Gestion utilisateur": "/gestion"
};

const makeTabStr = role => {
  switch (role) {
    case ROLE_ADMIN:
      return ["Gestion utilisateur", "Historique"];
    case ROLE_COMMISSION:
      return ["En cours", "Historique"];
    case ROLE_DIRECTOR:
      return ["Partage", "Historique"];
    default:
      return [];
  }
};

function Nav({ history }) {
  const me = useContext(MeContext);

  let currentKey = null;
  for (const key in tabKey) {
    if (
      tabKey[key] &&
      tabKey[key].replace("/", "") ===
        history.location.pathname.replace(/\//g, "")
    ) {
      currentKey = key;
      break;
    }
  }

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[currentKey]}
      style={{ lineHeight: "64px" }}
    >
      {makeTabStr(me.role).map(tab => (
        <Menu.Item key={tab}>
          <NavLink href={tabKey[tab]}>{tab}</NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default withRouter(Nav);
