import React, { useContext } from "react";
import { NavLink } from "react-navi";
import { Menu, Icon, Dropdown, Avatar } from "antd";

import "./style.css";
import authService from "../services/auth-service";
import { MeContext } from "../components/context";

export default function Profile() {
  const me = useContext(MeContext);

  const menu = (
    <Menu>
      <Menu.Item key="userinfo">
        <NavLink href="/profile">Mon profil</NavLink>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="logout" onClick={authService.signOut}>
        <Icon type="logout" />
        <span>Se déconnecter</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <span className="profile">
        <Avatar icon="user" size="small" />
        <span className="profile-title">
          {me.firstname} {me.lastname}
        </span>
      </span>
    </Dropdown>
  );
}
