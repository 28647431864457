import React from "react";
import {
  NavLoading,
  NavProvider,
  NavContent,
  NavNotFoundBoundary,
  NavLink
} from "react-navi";
import BusyIndicator from "react-busy-indicator";
import { Exception } from "ant-design-pro";

import { MeProvider } from "./features/me";

export default function App({ navigation }) {
  return (
    <NavProvider navigation={navigation}>
      <NavLoading>
        {busyRoute => (
          <NavNotFoundBoundary render={renderNotFound}>
            <BusyIndicator isBusy={busyRoute} color="#0aa679" />
            <MeProvider>
              <NavContent />
            </MeProvider>
          </NavNotFoundBoundary>
        )}
      </NavLoading>
    </NavProvider>
  );
}

function renderNotFound() {
  return (
    <Exception
      linkElement={NavLink}
      backText="Retour à l'accueil"
      desc="Page non trouvée"
      type="404"
    />
  );
}
