import React from "react";
import { Layout } from "antd";

import "./header.css";
import Nav from "./nav";
import { Profile } from "../../features/me";

const { Header } = Layout;

export default function MyHeader() {
  return (
    <Header className="header">
      <div className="logo">
        <img src={require("../../img/logo.jpg")} alt="logo" />
      </div>
      <Nav />
      <Profile />
    </Header>
  );
}
