import React, { Suspense } from "react";
import { Layout } from "antd";
import { NavContent } from "react-navi";

import Header from "./header";
const { Content } = Layout;

export default function AuthLayout() {
  return (
    <Layout>
      <Header />
      <Content style={{ padding: "85px 50px 0" }}>
        <Suspense fallback={null}>
          <NavContent />
        </Suspense>
      </Content>
    </Layout>
  );
}
