import React from "react";

import authService from "../services/auth-service";

export const MeContext = React.createContext(null);

export class MeProvider extends React.Component {
  static context = MeContext;

  render() {
    return (
      <MeContext.Provider value={authService.getCurrentUser()}>
        {this.props.children}
      </MeContext.Provider>
    );
  }
}

export const withMe = WrappedComponent =>
  class WithMe extends React.Component {
    static displayName = `withMe(${WrappedComponent.displayName})`;

    render() {
      return (
        <MeContext.Consumer>
          {user => <WrappedComponent {...this.props} user={user} />}
        </MeContext.Consumer>
      );
    }
  };
