import Cookie from "js-cookie";

import api from "../../../lib/api";

const COOKIE = "centaure-me";
let subId = 1;

const b64DecodeUnicode = str =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

class AuthService {
  constructor() {
    this.initialState = { currentUser: null };

    this.state = { ...this.initialState };
    this.subscribers = new Map();

    this.refreshingToken = false;
  }

  setState(newState, notify = true) {
    this.state = { ...this.state, ...newState };

    // notify subscribers
    if (notify) {
      for (const sub of this.subscribers.values()) {
        sub(this.state);
      }
    }
  }

  subscribe(fn) {
    if (typeof fn !== "function") {
      throw new Error("Subscriber doit être une fonction");
    }

    this.subscribers.set(subId, fn);
    const id = subId;
    subId++;

    return () => {
      this.subscribers.delete(id);
    };
  }

  patchGlobalApi(token) {
    api.setHeader("Authorization", `bearer ${token}`);
  }

  hydrateUserFromCookie() {
    const token = Cookie.get(COOKIE);
    if (!token || this.isTokenExpired(token)) return;

    const payload = this.parseToken(token);

    this.patchGlobalApi(token);
    this.setState({ currentUser: { ...payload, token } });
  }

  updateUser(user) {
    this.setState({ currentUser: { ...this.state.currentUser, ...user } });
  }

  getCurrentUser() {
    return this.state.currentUser;
  }

  signIn = token => {
    this.patchGlobalApi(token);

    Cookie.set(COOKIE, token, {
      secure: process.env.NODE_ENV === "production"
    });

    const user = this.parseToken(token);

    this.setState({ currentUser: { ...user, token } });
  };

  signOut = () => {
    Cookie.remove(COOKIE);
    this.setState(this.initialState);
  };

  parseToken(token) {
    const tokenArray = token.split(".");
    if (!tokenArray[1]) throw new Error("Failed to parse token");
    return JSON.parse(b64DecodeUnicode(tokenArray[1]));
  }

  isTokenExpired(token) {
    const payload = this.parseToken(token);

    const dateExpiration = payload.exp;
    const dateCurrent = Math.floor(Date.now() / 1000);

    return dateCurrent >= dateExpiration;
  }

  isTokenSoonExpired(token) {
    const payload = this.parseToken(token);

    const dateExpiration = payload.exp;
    const dateCurrent = Math.floor(Date.now() / 1000);
    const secondBeforeExpired = dateExpiration - dateCurrent;

    return secondBeforeExpired < 1800;
  }

  checkToken() {
    const user = this.getCurrentUser();
    if (!user || this.refreshingToken) return;

    if (this.isTokenSoonExpired(user.token)) {
      this.refreshingToken = true;
      api.get("/auth/renew").then(response => {
        if (response.ok) {
          const newToken = response.data.access_token;
          this.setState(
            {
              currentUser: { ...user, token: newToken }
            },
            false
          );
          this.patchGlobalApi(newToken);
        }
        this.refreshingToken = false;
      });
    }
  }
}

export default new AuthService();
