import { createRedirect } from "navi";

export const authenticated = (page, allowedRole) => env => {
  if (!env.context.currentUser) {
    return createRedirect(
      "/login/?redirectTo=" + encodeURIComponent(env.pathname + env.search)
    );
  }
  if (!allowedRole.includes(env.context.currentUser.role)) {
    return createRedirect("/");
  }
  return page;
};
