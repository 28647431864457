import {
  create,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR
} from "apisauce";
import { notification } from "antd";
import debounce from "lodash/debounce";

import { authService } from "../features/me";

/**
 * Instance de l'api utilsée pour toutes les requêtes
 *
 * Le AuthService ajoute au header l'identifier du client après la connexion/hydratation du user
 * et ajoute le token en Authorization bearer
 */
const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

/**
 * Gestion de l'expiration du token
 *
 * Appel une fonction du authService pour check si le token est bientôt expiré
 * decode base64 pour le token
 */
api.addRequestTransform(request => {
  authService.checkToken();
});

api.addMonitor(response => {
  if (!response.ok) {
    if (response.status === 401) {
      authService.signOut();
    } else if (response.problem === CLIENT_ERROR) return; // pas de notification pour des erreurs 400 sauf 401

    notifyError(response.problem);
  }
});

const notifyError = debounce(problem => {
  switch (problem) {
    case SERVER_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Une erreur est survenue sur le serveur."
      });
      break;
    case TIMEOUT_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Le serveur est surchargé."
      });
      break;
    case CONNECTION_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Le serveur n'est pas disponible."
      });
      break;
    case NETWORK_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Vous n'êtes pas connecté à internet."
      });
      break;
    case CLIENT_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Vous n'êtes plus authentifié."
      });
      break;
    default:
      notification.error({
        message: "Erreur!",
        description:
          "Une erreur non identifiée est survenue, veuillez consulter la console."
      });
      break;
  }
}, 300);

export default api;
