import React from "react";
import ReactDOM from "react-dom";
import { createBrowserNavigation } from "navi";
import { LocaleProvider } from "antd";
import frFR from "antd/lib/locale-provider/fr_FR";
import moment from "moment";
import "moment/locale/fr";

import "./index.css";
import App from "./app";
import pages from "./pages";
import { authService } from "./features/me";
import GlobalErrorBoundary from "./components/global-error-boundary";

moment.locale("fr");

authService.hydrateUserFromCookie();
const navigation = createBrowserNavigation({
  pages,
  context: {
    currentUser: authService.getCurrentUser()
  }
});

authService.subscribe(() =>
  navigation.setContext({
    currentUser: authService.getCurrentUser()
  })
);

ReactDOM.render(
  <LocaleProvider locale={frFR}>
    <GlobalErrorBoundary>
      <App navigation={navigation} />
    </GlobalErrorBoundary>
  </LocaleProvider>,
  document.getElementById("root")
);
