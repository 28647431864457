import React from "react";
import { NavHistory } from "react-navi";

const withRouter = WrappedComponent => {
  return class WithRouter extends React.Component {
    static displayName = `withRouter(${WrappedComponent.displayName})`;

    render() {
      const { ...restProps } = this.props;

      return (
        <NavHistory>
          {history => <WrappedComponent {...restProps} history={history} />}
        </NavHistory>
      );
    }
  };
};

export default withRouter;
