export const ROLE_ADMIN = "ADMIN";
export const ROLE_DIRECTOR = "DIRECTOR";
export const ROLE_COMMISSION = "COMMISSION";

export const STATUS = {
  PENDING: "PENDING",
  TODO: "TODO",
  REJECTED: "REJECTED",
  VALIDATED: "VALIDATED"
};

export const STATUS_STR = {
  PENDING: "Commission",
  TODO: "2J Process",
  REJECTED: "Refusé",
  VALIDATED: "Traité"
};
