import React from "react";
import { createPage, createSwitch, createRedirect } from "navi";

import { authenticated } from "../lib/guard";
import AuthLayout from "../components/layouts/auth-layout";
import { ROLE_ADMIN, ROLE_DIRECTOR, ROLE_COMMISSION } from "../lib/constants";

const LoginPage = React.lazy(() => import("./login"));

const makeTitle = title => `${title} | Centaure Logiciel`;

export default createSwitch({
  getContent: env => (!env.context.currentUser ? undefined : <AuthLayout />),

  paths: {
    "/": env => {
      if (!env.context.currentUser) {
        return createRedirect(
          "/login/?redirectTo=" + encodeURIComponent(env.pathname + env.search)
        );
      }

      switch (env.context.currentUser.role) {
        case ROLE_ADMIN:
          return createRedirect("/gestion");
        case ROLE_DIRECTOR:
          return createRedirect("/partage");
        case ROLE_COMMISSION:
          return createRedirect("/validation");
        default:
          return null;
      }
    },

    "/login": env => {
      // Si on a un utilisateur on bypass le login
      if (env.context.currentUser)
        return createRedirect(
          env.params.redirectTo
            ? decodeURIComponent(env.params.redirectTo)
            : "/"
        );

      return createPage({
        title: makeTitle("Connexion"),
        content: <LoginPage />
      });
    },

    "/profile": authenticated(
      createPage({
        title: makeTitle("Profil"),
        getContent: () => import("./profile")
      }),
      [ROLE_ADMIN, ROLE_DIRECTOR, ROLE_COMMISSION]
    ),

    "/partage": authenticated(
      createPage({
        title: makeTitle("Partage"),
        getContent: () => import("./partage")
      }),
      [ROLE_DIRECTOR]
    ),

    "/historique": authenticated(
      createPage({
        title: makeTitle("Historique"),
        getContent: () => import("./historique")
      }),
      [ROLE_ADMIN, ROLE_DIRECTOR, ROLE_COMMISSION]
    ),

    "/validation": authenticated(
      createPage({
        title: makeTitle("Validation"),
        getContent: () => import("./validation")
      }),
      [ROLE_COMMISSION]
    ),

    "/gestion": authenticated(
      createPage({
        title: makeTitle("Gestion des utilisateurs"),
        getContent: () => import("./gestion")
      }),
      [ROLE_ADMIN]
    )
  }
});
